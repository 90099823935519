import Vue from "vue";
import Vuex from "vuex";
import findIndex from "lodash/findIndex";

Vue.use(Vuex);

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("flash-messages");
  const store = new Vuex.Store({
    state: {
      messages: []
    },
    mutations: {
      addMessage(state, message) {
        const { type, text } = message;
        const index = findIndex(state.messages, { type, text });

        if (index === -1) {
          state.messages.push(message);
        }
      },
      clearMessages(state) {
        state.messages = [];
      },
      removeMessage(state, message) {
        const { type, text } = message;

        const filteredMessages = state.messages.filter(value => {
          return value.type !== type || value.text !== text;
        });

        state.messages = filteredMessages;
      }
    }
  });

  const flashMessages = new Vue({
    el,
    store,
    render(createElement) {
      const msgs = this.$store.state.messages.map(msg => {
        return createElement(
          "div",
          { class: `flash alert alert-${msg.type}` },
          msg.text
        );
      });

      return createElement("div", msgs);
    },
    methods: {
      add(type, text) {
        this.$store.commit("addMessage", { type, text });
      },
      clear() {
        this.$store.commit("clearMessages");
      },
      remove(type, text) {
        this.$store.commit("removeMessage", { type, text });
      }
    }
  });

  window.flashMessages = flashMessages;
});
